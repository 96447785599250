import { log } from '@/services/Log'
import allPromisesWithRetries from '@/helpers/allPromisesWithRetries'
import { withSessionCancelationHandling } from './withSessionCancelationHandling'

const getModules = async () => allPromisesWithRetries(() => [
  import('@/services/FramedCheckout/orderServices/getCart'),
  import('@/services/FramedCheckout/orderServices/getOrder'),
  import('@/services/FramedCheckout/shipmentServices/updateShippingOption'),
])
  .then(([
    { getCart },
    { getOrder },
    { updateShippingOption },
  ]) => ({
    getCart,
    getOrder,
    updateShippingOption,
  }))

const rawHandleShippingMethodSelected = ({
  session,
  onAbort,
  orderParser,
}: {
  session: ApplePaySession,
  onAbort: () => Promise<void> | void,
  orderParser: Awaited<typeof import('../transformers/transformOrderToApplePaySessionData')>['default'],
}) => async (
  event: ApplePayJS.ApplePayShippingMethodSelectedEvent,
) => {
  log('Apple Pay onshippingmethodselected: ', event)
  const {
    getCart,
    getOrder,
    updateShippingOption,
  } = await getModules()
  if (event.shippingMethod.identifier) {
    const cart = await getCart()
    if (!cart) {
      await onAbort()
      throw new Error('No cart found')
    }
    const order = await getOrder({ cart })
    const result = await updateShippingOption({
      order,
      shippingOptionId: event.shippingMethod.identifier,
      shipmentId: order.shipments[0].id,
    })

    const newOrder = (
      result.success && result.newOrder
        ? result.newOrder
        : await getOrder({ cart })
    )

    const newApplePayData = orderParser(newOrder)
    session.completeShippingMethodSelection({
      newTotal: newApplePayData.total,
      newLineItems: newApplePayData.lineItems,
    })
  }
}

export const handleShippingMethodSelected = (
  withSessionCancelationHandling(rawHandleShippingMethodSelected)
)

export default handleShippingMethodSelected
