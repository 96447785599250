import { error, log } from '@/services/Log'

import type {
  PaymentRequestPaymentMethodEvent,
  Stripe,
} from '@stripe/stripe-js'

import { type StripeModules } from '@/services/ApplePay/Stripe/fetchModules'
import type { StandardAddress, StandardCart } from '@/types/ShopFront/CheckoutStandards'
import { UpdateWithDefinedOrder, UpdateWithUndefinedOrder, assertUpdateWithDefinedOrder } from '@/services/ApplePay/Stripe/assertUpdateWithDefinedOrder'
import { poolForOrderCompletionCheck } from '@/services/ApplePay/Stripe/poolForOrderCompletionCheck'
import type { PaymentFlow } from '@/types/ShopFront/PaymentType'
import { getBaseProps } from '@/services/Standardizers/checkout/getCscPaymentBaseProps'
import {
  APPLE_PAY_FIELD,
  CSC_PAYMENTS_GATEWAY,
} from '@/services/Configuration'

export type ProductAdder = () => (
  Promise<{
    success: boolean,
    newCart: StandardCart | null,
  }>
)

const getFirstNameAssumedFromSpace = (name?: string) => String(name || '').trim().split(' ')[0]
const getLastNameAssumedFromSpace = (
  (name?: string) => (
    String(name || '')
      .trim()
      .split(' ')
      .slice(1)
      .join(' ')
  )
)

const verifiedStep = async ({
  updatePromise,
  complete,
  errorMessage,
}: {
  updatePromise: Promise<UpdateWithDefinedOrder | UpdateWithUndefinedOrder>,
  complete: (status: string) => void,
  errorMessage: string,
}): Promise<UpdateWithDefinedOrder | null> => {
  let returnValue: UpdateWithDefinedOrder | null = null
  try {
    returnValue = assertUpdateWithDefinedOrder(
      await updatePromise,
      errorMessage,
    )
  } catch (err) {
    error(`ApplePaySessionWithStripe: ${errorMessage}`)
    complete('fail')
  }
  return returnValue
}

export const onPaymentMethod = (
  modulesPromise: StripeModules,
  stripe: Stripe | null,
  beforeEnding: () => Promise<unknown>,
  shouldSetShipping: boolean,
  walletName: 'link' | 'applePay',
  paymentFlow: PaymentFlow,
) => async (event: PaymentRequestPaymentMethodEvent) => {
  log('ApplePaySessionWithStripe: paymentmethod', { event })
  const {
    getOrder,
    getCart,
    updateBillingAddress,
    updateShippingAddress,
    mapStateToFullName,
    mapStateToAcronym,
    customerPromise,
    postOrderFlow,
    confirmPaymentIntent,
    completePaymentWithCSC,
    credentialsPromise,
    goToConfirmation,
    getNonShippedStates,
  } = await modulesPromise
  const {
    shippingAddress,
    payerEmail,
    payerName,
    paymentMethod,
  } = event
  if (!shippingAddress && shouldSetShipping) {
    event.complete('fail')
    error('Missing final shipping address')
    throw new Error('Missing shipping address')
  }
  const cart = await getCart()
  if (!cart) {
    event.complete('fail')
    error('Missing cart')
    throw new Error('Missing cart')
  }
  if (!paymentMethod.billing_details) {
    event.complete('fail')
    error('Missing paymentMethod.billing_details')
    throw new Error('Missing paymentMethod.billing_details')
  }
  const billingDetails = paymentMethod.billing_details
  const billingAddress = billingDetails.address
  let order = await getOrder({ cart })
  if (shouldSetShipping) {
    if (!shippingAddress) {
      event.complete('fail')
      error('Missing final shipping address')
      throw new Error('Missing shipping address')
    }
    const finalShippingAddress = {
      addressType: 'residential',
      firstName: (
        getFirstNameAssumedFromSpace(shippingAddress.recipient)
        || getFirstNameAssumedFromSpace(payerName)
      ),
      lastName: (
        getLastNameAssumedFromSpace(shippingAddress.recipient)
        || getLastNameAssumedFromSpace(payerName)
      ),
      street1: shippingAddress.addressLine?.[0] || '',
      street2: shippingAddress.addressLine?.[1] || '',
      city: shippingAddress.city || '',
      stateName: mapStateToFullName(shippingAddress.region || ''),
      stateAcronym: mapStateToAcronym(shippingAddress.region || ''),
      zip: shippingAddress.postalCode || '',
      phone: (
        shippingAddress.phone
        || billingDetails.phone
        || ''
      ),
      country: shippingAddress.country || '',
      countryCode: 'US',
      email: (
        (await customerPromise)?.email
        || order?.billingAddress?.email
        || payerEmail
        || billingDetails.email
        || order.cart.email
      ),
      company: '',
      customFields: [],
      shouldSaveAddress: false,
    }
    if (getNonShippedStates().includes(finalShippingAddress.stateAcronym)) {
      event.complete('fail')
      throw new Error(`Shipping address targets ${finalShippingAddress.stateAcronym} forbidden states: ${getNonShippedStates().join(', ')}}`)
    }
    const orderWithFinalShippingAddress = await verifiedStep({
      updatePromise: updateShippingAddress({
        address: finalShippingAddress,
        order,
      }),
      complete: event.complete,
      errorMessage: 'paymentmethod: Failed to update shipping address',
    })
    if (!orderWithFinalShippingAddress) {
      return
    }
    order = orderWithFinalShippingAddress.newOrder
  }
  const finalBillingAddress: StandardAddress = {
    addressType: 'residential',
    firstName: (
      getFirstNameAssumedFromSpace(payerName)
    ),
    lastName: (
      getLastNameAssumedFromSpace(payerName)
    ),
    street1: billingAddress?.line1 || '',
    street2: billingAddress?.line2 || '',
    city: billingAddress?.city || '',
    stateName: mapStateToFullName(billingAddress?.state || ''),
    stateAcronym: mapStateToAcronym(billingAddress?.state || ''),
    zip: billingAddress?.postal_code || '',
    phone: (
      billingDetails.phone
      || order?.billingAddress?.phone
      || order.shipments?.[0]?.shippingAddress?.phone
      || ''
    ),
    country: billingAddress?.country || '',
    countryCode: 'US',
    email: (
      (await customerPromise)?.email
      || order?.billingAddress?.email
      || payerEmail
      || billingDetails.email
      || order.cart.email
    ),
    company: '',
    customFields: [],
    shouldSaveAddress: false,
  }
  const orderWithFinalBillingAddress = await verifiedStep({
    updatePromise: updateBillingAddress({
      billingAddress: {
        ...finalBillingAddress,
        customFields: [
          ...(finalBillingAddress.customFields || []),
          { fieldId: String(APPLE_PAY_FIELD), fieldValue: 'true' },
        ],
      },
      order,
      customer: await customerPromise,
    }),
    complete: event.complete,
    errorMessage: 'paymentmethod: Failed to update billing address',
  })
  if (!orderWithFinalBillingAddress) {
    return
  }
  try {
  // Now call CSC-Payments to finalize the order
    const credentials = await credentialsPromise
    const {
      success, orderId, paymentId, paymentIntentSecret,
    } = await completePaymentWithCSC({
      credentials,
      order: orderWithFinalBillingAddress.newOrder,
      cardToken: undefined,
      methodToken: undefined,
      paymentsBody: {
        ...getBaseProps(orderWithFinalBillingAddress.newOrder),
        paymentIntent: true,
        paymentIntentData: {
          walletName,
        },
        identity: credentials.identitySourceId,
        paymentMethodId: event.paymentMethod.id,
        paymentGatewayName: CSC_PAYMENTS_GATEWAY,
      },
      paymentFlow,
      paypalPaymentData: undefined,
      applePayPaymentData: event.paymentMethod.id,
    })
    if (success && orderId && paymentId && paymentIntentSecret) {
      await confirmPaymentIntent({
        orderId,
        paymentId,
        paymentIntentSecret,
        stripe,
      })
      event.complete('success')
      await poolForOrderCompletionCheck({
        orderId,
        billingAddressZip: orderWithFinalBillingAddress.newOrder.billingAddress.zip,
        modulesPromise,
      })
      try {
        await postOrderFlow({
          order: {
            ...orderWithFinalBillingAddress.newOrder,
            orderId,
          },
          paymentFlow,
        })
        await beforeEnding()
      } catch (err) {
        error('ApplePaySessionWithStripe: Failed to run beforeEnding', err)
      }
      await goToConfirmation({
        orderId,
        zip: finalBillingAddress.zip,
      })
    } else {
      throw new Error('Failed to complete payment')
    }
  } catch (err) {
    error(`ApplePaySessionWithStripe: Failed to confirm payment intent: ${String(err)}`, err)
    event.complete('fail')
    throw err
  }
}

export default onPaymentMethod
