import { error, log } from '@/services/Log'

export const withSessionCancelationHandling = <
HookBuilderProps extends { onAbort?: () => Promise<void> | void },
K,
>(
    hookBuilder: (hookBuilderProps: HookBuilderProps) => (event: K) => Promise<void> | void,
  ) => (hookBuilderProps: HookBuilderProps) => (event: K) => {
    Promise.resolve(hookBuilder(hookBuilderProps)(event))
      .catch(async (err) => {
        error(`Apple Pay Session crashed with Error ${String(err)}`, err)
        try {
          log('Attempting to execute onAbort routine')
          await hookBuilderProps?.onAbort?.()
        } catch (err2) {
          error(`Apple Pay Session crashed and abort routine failed ${String(err2)}`, err2)
        }
      })
  }

export default withSessionCancelationHandling
