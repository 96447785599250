import { error, log } from '@/services/Log'

import type {
  PaymentRequestShippingAddressEvent,
  PaymentRequestUpdateDetails,
  PaymentRequestShippingOptionEvent,
} from '@stripe/stripe-js'
import { type StripeModules } from '@/services/ApplePay/Stripe/fetchModules'
import type { StandardOrder } from '@/types/ShopFront/CheckoutStandards'
import { standardOrderToStripeOrderUpdate } from '@/services/ApplePay/Stripe/standardOrderToStripeOrderUpdate'

type SessionInitializationPromise = Promise<{
  newOrder: StandardOrder
}>

const getFirstNameAssumedFromSpace = (name?: string) => String(name || '').trim().split(' ')[0]
const getLastNameAssumedFromSpace = (
  (name?: string) => (
    String(name || '')
      .trim()
      .split(' ')
      .slice(1)
      .join(' ')
  )
)

export const onShippingAddress = (
  modulesPromise: StripeModules,
) => (sessionInitializationPromise: SessionInitializationPromise) => async ({
  updateWith,
  shippingAddress,
}: PaymentRequestShippingAddressEvent) => {
  log('ApplePaySessionWithStripe: shippingaddresschange', { shippingAddress })
  if (shippingAddress.country !== 'US') {
    updateWith({ status: 'invalid_shipping_address' })
    return
  }
  const { newOrder } = await sessionInitializationPromise
  log('ApplePaySessionWithStripe: shippingaddresschange: sessionInitializationPromise', { newOrder })
  const {
    updateShippingAddress,
    mapStateToFullName,
    mapStateToAcronym,
    getOrder,
    getCart,
  } = await modulesPromise
  const cart = await getCart()
  if (!cart) {
    error('ApplePaySessionWithStripe: shippingaddresschange: Failed to get cart')
    throw new Error('Failed to get cart')
  }
  const order = await getOrder({ cart })
  log('ApplePaySessionWithStripe: shippingaddresschange: getOrder', { order })
  const address = {
    addressType: 'residential',
    firstName: getFirstNameAssumedFromSpace(shippingAddress.recipient),
    lastName: getLastNameAssumedFromSpace(shippingAddress.recipient),
    street1: shippingAddress.addressLine?.[0] || '',
    street2: shippingAddress.addressLine?.[1] || '',
    city: shippingAddress.city || '',
    stateName: mapStateToFullName(shippingAddress.region || ''),
    stateAcronym: mapStateToAcronym(shippingAddress.region || ''),
    zip: shippingAddress.postalCode || '',
    phone: shippingAddress.phone || '',
    country: shippingAddress.country || '',
    countryCode: 'US',
    email: newOrder.cart.email,
    company: '',
    customFields: [],
    shouldSaveAddress: false,
  }
  log('ApplePaySessionWithStripe: shippingaddresschange: updateShippingAddress', { address })
  const { success, newOrder: updatedOrder } = await updateShippingAddress({
    address,
    order,
  })
  log('ApplePaySessionWithStripe: shippingaddresschange: updateShippingAddress: success', { success, updatedOrder })
  let update: PaymentRequestUpdateDetails
  if (!success || !updatedOrder) {
    update = ({
      status: 'fail',
      ...standardOrderToStripeOrderUpdate(true)(newOrder),
    })
  } else {
    update = ({
      ...standardOrderToStripeOrderUpdate(true)(updatedOrder),
      status: 'success',
    })
  }
  log('ApplePaySessionWithStripe: shippingaddresschange: updateShippingAddress: update', { update })
  updateWith(update)
}

export const onShippingOption = (
  modulesPromise: StripeModules,
) => (sessionInitializationPromise: SessionInitializationPromise) => async ({
  updateWith,
  shippingOption,
}: PaymentRequestShippingOptionEvent) => {
  log('ApplePaySessionWithStripe: shippingaddresschange', { shippingOption })
  const { newOrder } = await sessionInitializationPromise
  log('ApplePaySessionWithStripe: shippingaddresschange: sessionInitializationPromise', { newOrder })
  const {
    updateShippingOption,
    getOrder,
  } = await modulesPromise
  const order = await getOrder({ cart: newOrder.cart })
  log('ApplePaySessionWithStripe: shippingoptionchange', { shippingOption })
  const { success, newOrder: updatedOrder } = await updateShippingOption({
    shipmentId: order.shipments[0].id,
    shippingOptionId: shippingOption.id,
    order,
  })
  log('ApplePaySessionWithStripe: shippingoptionchange', { success, updatedOrder })
  let update: PaymentRequestUpdateDetails
  const failed = (
    !success
    || !updatedOrder
    || updatedOrder.shipments[0].selectedShippingOption?.id !== shippingOption.id
  )
  if (failed) {
    update = ({
      status: 'fail',
      ...standardOrderToStripeOrderUpdate(true)(newOrder),
    })
  } else {
    update = ({
      ...standardOrderToStripeOrderUpdate(true)(updatedOrder),
      status: 'success',
    })
  }
  log('ApplePaySessionWithStripe: shippingoptionchange', { update })
  updateWith(update)
}
