export const getApplePayShippingMethods = (
  order: {
    shipments: Array<{
      availableShippingOptions: Array<{
        id: string
        title: string
        description: string
        cost: number
      }>
    }>
  },
): ApplePayJS.ApplePayShippingMethod[] => (
  order.shipments[0]?.availableShippingOptions.map((shippingOption) => ({
    identifier: shippingOption.id,
    label: shippingOption.title,
    detail: shippingOption.description,
    amount: String(shippingOption.cost),
  }))
)

export default getApplePayShippingMethods
