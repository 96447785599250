import { log } from '@/services/Log'
import allPromisesWithRetries from '@/helpers/allPromisesWithRetries'
import { withSessionCancelationHandling } from './withSessionCancelationHandling'

const getModules = async () => allPromisesWithRetries(() => [
  import('@/services/FramedCheckout/orderServices/getCart'),
  import('@/services/FramedCheckout/orderServices/getOrder'),
  import('@/services/FramedCheckout/shipmentServices'),
  import('../transformers/applePayPaymentContactToStandardAddress'),
])
  .then(([
    { getCart },
    { getOrder },
    { updateShippingAddress },
    { applePayPaymentContactToStandardAddress },
  ]) => ({
    getCart,
    getOrder,
    updateShippingAddress,
    applePayPaymentContactToStandardAddress,
  }))

const informSessionOfStatus = (session: ApplePaySession) => (order: {
  shippingMethods: ApplePayJS.ApplePayShippingMethod[],
  total: ApplePayJS.ApplePayLineItem,
  lineItems: ApplePayJS.ApplePayLineItem[]
}) => (status: number) => {
  session.completeShippingContactSelection(
    status,
    order.shippingMethods,
    order.total,
    order.lineItems,
  )
}

const rawShippingHandler = ({
  session,
  beforeInitialize,
  onAbort,
  orderParser,
  customerPromise,
}: {
  session: ApplePaySession,
  beforeInitialize?: () => Promise<void>,
  onAbort: () => Promise<void> | void,
  orderParser: Awaited<typeof import('../transformers/transformOrderToApplePaySessionData')>['default'],
  customerPromise: Promise<{ customerGroupId: number } | null>
}) => async (
  event: ApplePayJS.ApplePayShippingContactSelectedEvent,
) => {
  log('Apple Pay onshippingcontactselected: ', event)
  const {
    getCart,
    getOrder,
    updateShippingAddress,
    applePayPaymentContactToStandardAddress,
  } = await getModules()
  if (beforeInitialize) {
    await beforeInitialize()
  }
  if (event.shippingContact) {
    const newAddress = applePayPaymentContactToStandardAddress(event.shippingContact)
    const cart = await getCart()
    if (!cart) {
      await onAbort()
    } else {
      const order = await getOrder({ cart })
      if (!newAddress.zip || newAddress.country !== 'US') {
        log('STATUS_INVALID_SHIPPING_CONTACTß')
        informSessionOfStatus(session)(
          orderParser(order),
        )(ApplePaySession.STATUS_INVALID_SHIPPING_CONTACT)
        throw new Error('Invalid Shipping address')
      } else {
        const customer = await customerPromise
        const { success, newOrder } = await updateShippingAddress({
          address: newAddress,
          order,
          customer,
        })
        if (
          success
            && newOrder
            && newOrder?.shipments?.[0]?.availableShippingOptions.length > 0
        ) {
          informSessionOfStatus(session)(
            orderParser(newOrder),
          )(ApplePaySession.STATUS_SUCCESS)
        } else {
          informSessionOfStatus(session)(
            orderParser(order),
          )(ApplePaySession.STATUS_FAILURE)
        }
      }
    }
  }
}

export const handleShippingAddressSelected = withSessionCancelationHandling(rawShippingHandler)

export default handleShippingAddressSelected
