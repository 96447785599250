import { CSC_PAYMENTS_SHOP } from '@/services/Configuration'
import { StandardOrder } from '@/types/ShopFront/CheckoutStandards'
import { getAntiFraudShippingAddress } from '@/services/Standardizers/checkout/getAntiFraudShippingAddress'
import { lineItemsTransformer } from '@/helpers/cscPaymentsLineItemTransformer'

const getOrderEmail = (order: StandardOrder) => (
  order.shipments[0]?.shippingAddress?.email || order.billingAddress.email || order.cart.email || ''
)

const getOrderCoupon = (order: StandardOrder) => (
  order.cart.coupons?.length ? order.cart.coupons[0].code : ''
)

const getDeviceFingerprintId = () => (
  window?.localStorage?.getItem('dfSessionId') ?? ''
)

const getUserType = (order: StandardOrder) => (
  order.customerId ? 'registered' : 'guest'
)

export const getBaseProps = (order: StandardOrder) => ({
  shopName: CSC_PAYMENTS_SHOP,
  checkoutId: order.cart.id,
  totalAmount: order.grandTotal,
  // anti-fraud DM below this point
  shippingAddress: getAntiFraudShippingAddress(
    order.shipments[0]?.shippingAddress,
    getOrderEmail(order),
  ),
  billingAddress: {
    ...order.billingAddress,
    country: order.billingAddress.countryCode,
    state: order.billingAddress.stateAcronym,
    postalCode: order.billingAddress.zip,
  },
  lineItems: lineItemsTransformer(order.cart),
  orderContext: {
    registeredOrGuest: getUserType(order),
    channel: 'ECOM',
    couponOrPromoCode: getOrderCoupon(order),
  },
  deviceFingerprintId: getDeviceFingerprintId(),
  useStoreCredit: order.isStoreCreditApplied,
})

export default getBaseProps
