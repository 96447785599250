import { getCustomer } from '@/helpers/graphql'
import { FEATURE_TOGGLES } from '@/services/Configuration'
import { error, log } from '@/services/Log'
import getBraintreeApplePayClient from '@/services/ApplePay/getBraintreeApplePayClient'
import { handleApplePayMerchantValidation } from '@/services/ApplePay/handlers/handleApplePayMerchantValidation'
import { handlePaymentAuthorized } from '@/services/ApplePay/handlers/handlePaymentAuthorized'
import { handlePaymentMethodSelected } from '@/services/ApplePay/handlers/handlePaymentMethodSelected'
import { handleSessionCancelled } from '@/services/ApplePay/handlers/handleSessionCancelled'
import { handleShippingAddressSelected } from '@/services/ApplePay/handlers/handleShippingAddressSelected'
import { handleShippingMethodSelected } from '@/services/ApplePay/handlers/handleShippingMethodSelected'
import { transformOrderToApplePaySessionData } from '@/services/ApplePay/transformers/transformOrderToApplePaySessionData'
import type { PaymentFlow } from '@/types/ShopFront/PaymentType'

const isApplePayEnabled = () => (
  !!FEATURE_TOGGLES?.applePayEnabled
  || (new URLSearchParams(window.location.search).get('forceApplePayOn') === 'true')
)
const defaultHookWhenNotProvided = () => Promise.resolve()
export const startSession = ({
  logPrefix,
  initializationFunction = defaultHookWhenNotProvided,
  initialPaymentRequest,
  beforeAbort = defaultHookWhenNotProvided,
  beforeRedirectOnSuccess = defaultHookWhenNotProvided,
  orderParser = transformOrderToApplePaySessionData,
  paymentFlow,
}: {
  showShipmentOptions?: boolean,
  logPrefix: string,
  initializationFunction?: () => (
    Promise<unknown>
  ),
  initialPaymentRequest: ApplePayJS.ApplePayPaymentRequest,
  beforeAbort?: () => Promise<void>
  beforeRedirectOnSuccess?: () => Promise<void>
  orderParser?: typeof transformOrderToApplePaySessionData,
  paymentFlow: PaymentFlow,
}) => {
  if (!isApplePayEnabled()) {
    throw new Error('Apple Pay is not enabled')
  }
  if (isApplePayEnabled()) {
    const prepNewSingleItemCheckoutPromise = initializationFunction()
    const session = new ApplePaySession(3, initialPaymentRequest)
    const onAbort = async () => {
      try {
        await beforeAbort()
      } catch (err) {
        error(`${logPrefix}: Failed to recreate cart after Apple Pay session abort`, err)
      }
      try {
        session?.abort?.()
      } catch (err) {
        error(`${logPrefix}: Failed to abort PDP Apple Pay session`, err)
      }
    }

    const braintreeApplePayClientPromise = (
      prepNewSingleItemCheckoutPromise
        .then(() => getBraintreeApplePayClient())
        .catch((err) => {
          error(`${logPrefix}: Failed to get Braintree Apple Pay client`, err)
          session.abort()
        })
    )

    const merchantValidator = async (event: ApplePayJS.ApplePayValidateMerchantEvent) => {
      log(`${logPrefix}: Apple Pay onvalidatemerchant: `, {
        event,
        typeOfBrainTreeClientPromise: typeof braintreeApplePayClientPromise,
      })
      const braintreeApplePayClient = await braintreeApplePayClientPromise
      if (!braintreeApplePayClient) {
        error('braintreeApplePayClient is null')
        session?.abort?.()
      } else {
        log(`${logPrefix}: braintreeApplePayClient: `, {
          braintreeApplePayClient,
        })
        return braintreeApplePayClient.performValidation({
          displayName: 'apple pay test',
          validationURL: event.validationURL,
        })
      }
      return undefined
    }

    const applePayTokenGenerator = async (event: ApplePayJS.ApplePayPaymentAuthorizedEvent) => {
      let token = ''
      const braintreeApplePayClient = await braintreeApplePayClientPromise
      if (!braintreeApplePayClient) {
        error('braintreeApplePayClient is null')
        session?.abort?.()
      } else {
        log(`${logPrefix}: braintreeApplePayClient: `, {
          braintreeApplePayClient,
        })
        token = (await braintreeApplePayClient.tokenize(event.payment)).nonce
      }
      return token
    }

    const customerPromise = (
      getCustomer()
        .then(({ success, customer }) => {
          if (success && customer) {
            return customer
          }
          return null
        })
        .catch(() => null)
    )

    session.onvalidatemerchant = handleApplePayMerchantValidation({
      beforeInitialize: async () => {
        await prepNewSingleItemCheckoutPromise
      },
      session,
      onAbort,
      merchantValidator,
    })

    session.onshippingcontactselected = handleShippingAddressSelected({
      session,
      beforeInitialize: async () => {
        try {
          await prepNewSingleItemCheckoutPromise
        } catch (err) {
          error('Failed to prepare apple pay data', err)
          await onAbort()
        }
      },
      onAbort,
      orderParser,
      customerPromise,
    })

    session.onshippingmethodselected = (
      handleShippingMethodSelected({
        orderParser,
        session,
        onAbort,
      })
    )
    session.onpaymentmethodselected = (
      handlePaymentMethodSelected({
        session,
        orderParser,
        onAbort,
      })
    )
    session.onpaymentauthorized = handlePaymentAuthorized({
      session,
      customerPromise,
      applePayTokenGenerator,
      onAbort,
      beforeFinalize: beforeRedirectOnSuccess,
      paymentFlow,
    })

    session.oncancel = handleSessionCancelled({
      beforeFinalize: beforeAbort,
    })

    session.begin()
  }
}

export default startSession
