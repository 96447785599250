import { error, log } from '@/services/Log'

export const handleApplePayMerchantValidation = ({
  session,
  beforeInitialize,
  onAbort,
  merchantValidator,
}: {
  beforeInitialize: () => Promise<void>,
  onAbort: () => Promise<void> | void,
  merchantValidator: (event: ApplePayJS.ApplePayValidateMerchantEvent) => Promise<unknown>
  session: ApplePaySession,
}) => (event: ApplePayJS.ApplePayValidateMerchantEvent) => {
  log('Apple Pay onvalidatemerchant: ', event)
  if (event.validationURL) {
    Promise.resolve(beforeInitialize())
      .then(() => {
        log('Apple Pay merchant validator beforeInitialize resolved')
        return Promise.resolve(merchantValidator(event))
      })
      .then((merchantSession) => {
        log('Apple Pay merchant session: ', merchantSession)
        if (!merchantSession) {
          throw new Error('No merchant session')
        }
        session.completeMerchantValidation(merchantSession)
      })
      .catch(async (err) => {
        error('Error fetching merchant session', err)
        await onAbort()
      })
  }
}

export default handleApplePayMerchantValidation
