import { getCart } from '@/services/FramedCheckout/orderServices/getCart'
import { getOrder } from '@/services/FramedCheckout/orderServices/getOrder'
import { log } from '@/services/Log'
import { withSessionCancelationHandling } from './withSessionCancelationHandling'

const rawHandlePaymentMethodSelected = ({
  session,
  onAbort,
  orderParser,
}: {
  session: ApplePaySession,
  onAbort: () => Promise<void> | void,
  orderParser: Awaited<typeof import('../transformers/transformOrderToApplePaySessionData')>['default'],
}) => async (
  event: ApplePayJS.ApplePayPaymentMethodSelectedEvent,
) => {
  log('Apple Pay onpaymentmethodselected: ', event)
  const cart = await getCart()
  if (!cart) {
    await onAbort()
    throw new Error('No cart found')
  }
  const order = await getOrder({ cart })
  const currentApplePayData = orderParser(order)
  session.completePaymentMethodSelection({
    newTotal: currentApplePayData.total,
    newLineItems: currentApplePayData.lineItems,
  })
}

export const handlePaymentMethodSelected = (
  withSessionCancelationHandling(rawHandlePaymentMethodSelected)
)

export default handlePaymentMethodSelected
