import { log } from '@/services/Log'

import type {
  PaymentRequestUpdateDetails,
} from '@stripe/stripe-js'
import type { StandardOrder } from '@/types/ShopFront/CheckoutStandards'
import { toStripeMoney } from '@/services/ApplePay/Stripe/toStripeMoney'

const stripeShippingOptionsFromStandardOrder = (
  order: StandardOrder,
) => (
  order.shipments[0]?.availableShippingOptions.map((option) => ({
    id: option.id,
    label: option.title,
    amount: toStripeMoney(option.cost),
    selected: option.id === order.shipments[0]?.selectedShippingOption?.id,
    detail: option.description,
  }))
)

const displayItemsFromStandardOrder = (
  order: StandardOrder,
) => [
  {
    amount: toStripeMoney(order.subtotal),
    label: 'subtotal',
  },
  {
    amount: toStripeMoney(order.shippingCostTotal),
    label: 'Shipping',
  },
  {
    amount: toStripeMoney(order.taxTotal),
    label: 'Tax',
  },
]

export const standardOrderToStripeOrderUpdate = (
  shouldShowShippingOption?: boolean,
) => (order: StandardOrder): PaymentRequestUpdateDetails => {
  const retValue = ({
    status: undefined,
    total: {
      label: 'total',
      amount: toStripeMoney(order.grandTotal - order.storeCreditDiscount),
    },
    displayItems: displayItemsFromStandardOrder(order),
    shippingOptions: (
      shouldShowShippingOption
        ? stripeShippingOptionsFromStandardOrder(order)
        : undefined
    ),
  })
  log('ApplePaySessionWithStripe: updateFromOrder', { order, retValue })
  return retValue
}

export default standardOrderToStripeOrderUpdate
