import allPromisesWithRetries from '@/helpers/allPromisesWithRetries'
import { getCart } from '@/services/FramedCheckout/orderServices/getCart'
import { getOrder } from '@/services/FramedCheckout/orderServices/getOrder'
import { error, log } from '@/services/Log'
import { APPLE_PAY_FIELD } from '@/services/Configuration'
import { withSessionCancelationHandling } from '@/services/ApplePay/handlers/withSessionCancelationHandling'
import type { PaymentFlow } from '@/types/ShopFront/PaymentType'

type Customer = {
  firstName: string
  lastName: string
  email: string
  customerGroupId: number
}

const rawHandlePaymentAuthorized = ({
  session,
  applePayTokenGenerator,
  customerPromise,
  onAbort,
  beforeFinalize,
  paymentFlow,
}: {
  session: ApplePaySession,
  applePayTokenGenerator: (event: ApplePayJS.ApplePayPaymentAuthorizedEvent) => Promise<string>,
  customerPromise: Promise<Customer | null >
  onAbort: () => Promise<void> | void,
  beforeFinalize?: () => Promise<void>,
  paymentFlow: PaymentFlow,
}) => async (
  event: ApplePayJS.ApplePayPaymentAuthorizedEvent,
) => {
  log('Apple Pay onpaymentauthorized', event)
  const [
    { updateBillingAddress },
    { updateShippingAddress },
    { completePaymentWithParky },
    { applePayPaymentContactToStandardAddress },
    { goToConfirmation },
    { getCustomer },
  ] = await allPromisesWithRetries(() => [
    import('@/services/FramedCheckout/paymentServices'),
    import('@/services/FramedCheckout/shipmentServices/updateShippingAddress'),
    import('@/services/FramedCheckout/paymentServices/completePaymentWithParky'),
    import('@/services/ApplePay/transformers/applePayPaymentContactToStandardAddress'),
    import('@/services/FramedCheckout/navigationServices'),
    import('@/services/FramedCheckout/customerServices/getCustomer'),
  ])

  if (!event.payment.billingContact) {
    error('handlePaymentAuthorized billingContact is required', event)
    return session.completePayment({
      status: ApplePaySession.STATUS_FAILURE,
      errors: [{
        code: 'billingContactInvalid',
        message: 'Billing contact is required',
      }],
    })
  }
  const cart = await getCart()
  if (!cart) {
    error('handlePaymentAuthorized No cart found')
    await onAbort()
    throw new Error('No cart found')
  }

  if (!event.payment.shippingContact) {
    return session.completePayment({
      status: ApplePaySession.STATUS_FAILURE,
      errors: [{
        code: 'shippingContactInvalid',
        message: 'Shipping contact is missing',
      }],
    })
  }

  let order = await getOrder({ cart })
  const shippingAddress = applePayPaymentContactToStandardAddress(event.payment.shippingContact)
  const customer = await customerPromise
  const updateShippingResult = await updateShippingAddress({
    address: shippingAddress,
    order,
    customer: await Promise.resolve(customer),
  })

  if (!updateShippingResult.success || !updateShippingResult.newOrder) {
    return session.completePayment({
      status: ApplePaySession.STATUS_FAILURE,
      errors: [{
        code: 'shippingContactInvalid',
        message: 'Shipping contact is invalid',
      }],
    })
  }

  order = updateShippingResult.newOrder

  const billingAddress = applePayPaymentContactToStandardAddress(event.payment.billingContact)
  billingAddress.email = billingAddress.email || shippingAddress.email
  billingAddress.phone = billingAddress.phone || shippingAddress.phone
  if (!billingAddress.email || !billingAddress.phone) {
    return session.completePayment({
      status: ApplePaySession.STATUS_FAILURE,
      errors: [{
        code: 'billingContactInvalid',
        message: 'Billing contact is missing email or phone',
      }],
    })
  }
  const updateBillingResult = await updateBillingAddress({
    order,
    billingAddress: {
      ...billingAddress,
      customFields: [
        ...(billingAddress.customFields || []),
        { fieldId: String(APPLE_PAY_FIELD), fieldValue: 'true' },
      ],
    },
    customer: (
      order.cart.customerId
        ? await getCustomer()
        : null
    ),
  })
  if (!updateBillingResult.success || !updateBillingResult.newOrder) {
    error('handlePaymentAuthorized updateBillingAddress failed', updateBillingResult)
    return session.completePayment({
      status: ApplePaySession.STATUS_FAILURE,
      errors: [{
        code: 'billingContactInvalid',
        message: 'Billing contact is invalid',
      }],
    })
  }
  try {
    // get nonce from braintree sdk
    const token = await applePayTokenGenerator(event)
    log('token retrieved from applePayTokenGenerator')
    // complete payment with nonce
    const paymentResponse = await completePaymentWithParky({
      order: updateBillingResult?.newOrder,
      cardToken: token,
      shouldSaveInstrument: false,
      paymentFlow,
    })
    const success = paymentResponse?.is_success
    const orderId = paymentResponse?.order?.id
    log('completePayment result', { success, orderId, paymentResponse })
    if (success && orderId) {
      session.completePayment({
        status: ApplePaySession.STATUS_SUCCESS,
      })
      if (beforeFinalize) {
        try {
          await beforeFinalize()
        } catch (err) {
          error('handlePaymentAuthorized beforeFinalize failed', error)
        }
      }
      log(`goToConfirmation orderId:${orderId}, billingAddress.zip:${updateBillingResult.newOrder.billingAddress.zip}`)
      await goToConfirmation({
        orderId,
        zip: String(updateBillingResult.newOrder.billingAddress.zip || ''),
      })
      return undefined
    }
  } catch (err) {
    error('Payment Failed', err)
  }
  return session.completePayment({
    status: ApplePaySession.STATUS_FAILURE,
    errors: [{
      code: 'unknown',
      message: 'Payment failed',
    }],
  })
}

export const handlePaymentAuthorized = withSessionCancelationHandling(rawHandlePaymentAuthorized)
export default handlePaymentAuthorized
