import { StandardAddress } from '@/types/ShopFront/CheckoutStandards'

export const getAntiFraudShippingAddress = (
  address: StandardAddress | undefined,
  email: string,
) => (
  !address
    ? address
    : {
      street1: address.street1,
      street2: address.street2 || undefined,
      postalCode: address.zip,
      city: address.city,
      country: 'US',
      state: address.stateAcronym,
      firstName: address.firstName,
      lastName: address.lastName,
      phone: address.phone,
      email,
    }
)

export default getAntiFraudShippingAddress
