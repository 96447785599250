import { Stripe } from '@stripe/stripe-js'
import { error, log } from '@/services/Log'

import type {
  PaymentRequest,
} from '@stripe/stripe-js'
import { fetchModules } from '@/services/ApplePay/Stripe/fetchModules'
import type { StandardCart, StandardOrder } from '@/types/ShopFront/CheckoutStandards'
import {
  onShippingAddress,
  onShippingOption,
} from '@/services/ApplePay/Stripe/shippingEvents'

import { onPaymentMethod } from '@/services/ApplePay/Stripe/onPaymentMethod'
import { DispatchPaymentStatus } from '@/hooks/usePaymentStatus'
import type { PaymentFlow } from '@/types/ShopFront/PaymentType'

export type ProductAdder = () => (
  Promise<{
    success: boolean,
    newCart: StandardCart | null,
  }>
)

type SessionInitializationPromise = Promise<{
  newOrder: StandardOrder
}>
type StripeApplePaySessionStarterInput = {
  stripe: Stripe | null
  paymentRequest: PaymentRequest | null
  sessionInitializationPromise: SessionInitializationPromise
  shouldSetShipping?: boolean
  beforeEnding: () => Promise<unknown>
  walletName: 'link' | 'applePay',
  onError?: () => Promise<unknown>
  dispatchPaymentStatus?: DispatchPaymentStatus
  paymentFlow: PaymentFlow
}

const withAbort = (paymentRequest: PaymentRequest) => (onError: () => Promise<unknown>) => <T>(
  fn: (input: T) => Promise<void>,
) => async (input: T) => {
    log('ApplePaySessionWithStripe: withAbort', { input })
    try {
      await fn(input)
    } catch (err) {
      error(`Stripe Apple pay Session paymentRequest error: ${String(err)}`, err)
      await onError()
      paymentRequest.abort()
    }
  }

export const ApplePaySessionWithStripe = ({
  sessionInitializationPromise,
  paymentRequest,
  beforeEnding,
  stripe,
  shouldSetShipping = true,
  walletName,
  onError,
  dispatchPaymentStatus,
  paymentFlow,
}: StripeApplePaySessionStarterInput) => {
  log('ApplePaySessionWithStripe', { paymentRequest })
  if (!paymentRequest) {
    error('ApplePaySessionWithStripe: paymentRequest is null')
    return
  }
  const errorHandler = typeof onError === 'function' ? onError : beforeEnding
  const supervised = withAbort(paymentRequest)(errorHandler)
  const modulesPromise = fetchModules()
  log('ApplePaySessionWithStripe: supervised')
  paymentRequest.off('shippingaddresschange')
  paymentRequest.off('shippingoptionchange')
  paymentRequest.off('paymentmethod')
  paymentRequest?.off('cancel')
  paymentRequest.on('shippingaddresschange', supervised(onShippingAddress(modulesPromise)(sessionInitializationPromise)))
  log('ApplePaySessionWithStripe: subscribed to shippingaddresschange')
  paymentRequest.on('shippingoptionchange', supervised(onShippingOption(modulesPromise)(sessionInitializationPromise)))
  log('ApplePaySessionWithStripe: subscribed to shippingoptionchange')
  paymentRequest.on('paymentmethod', supervised(onPaymentMethod(
    modulesPromise,
    stripe,
    beforeEnding,
    shouldSetShipping,
    walletName,
    paymentFlow,
  )))
  log('ApplePaySessionWithStripe: subscribed to paymentmethod')
  paymentRequest?.on('cancel', () => {
    log('ApplePaySessionWithStripe: aborting payment request')
    dispatchPaymentStatus?.({
      type: 'fail',
      error: 'Unable to process your request, please try again or with a different payment method',
    })
    beforeEnding()
      .catch((err) => {
        error(`The PDP Failed to restore original cart: ${String(err)}`, err)
      })
    paymentRequest?.abort()
  })
  log('ApplePaySessionWithStripe: subscribed to cancel')
  paymentRequest.show()
  log('ApplePaySessionWithStripe: paymentRequest.show()')
}

export default ApplePaySessionWithStripe
