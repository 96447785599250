export const getApplePayOrderSummary = (order: {
  grandTotal: number,
  outstandingBalance: number,
  isStoreCreditApplied?: boolean,
  shipments: Array<{
    selectedShippingOption: null | {
      cost: number
    }
  }>
  subtotal: number
  taxTotal: number
  totalDiscount: number
  cart: {
    coupons: Array<{
      code: string
      discountedAmount: number
    }>
  }
}): ApplePayJS.ApplePayLineItem[] => {
  const shippingCosts = order.shipments[0]?.selectedShippingOption?.cost
  const lineItems: ApplePayJS.ApplePayLineItem[] = [
    {
      label: 'Subtotal',
      type: 'final',
      amount: String(order.subtotal),
    },
  ]
  if (shippingCosts) {
    lineItems.push({
      label: 'Shipping & Delivery:',
      type: 'final',
      amount: String(shippingCosts),
    })
  }
  lineItems.push({
    label: 'Tax',
    type: 'final',
    amount: String(order.taxTotal),
  })
  if (Array.isArray(order.cart?.coupons) && order.cart?.coupons?.length) {
    const coupons = order.cart.coupons || []
    const couponCodes = coupons.map((coupon) => coupon.code)
    const toalCouponDiscount = (
      coupons.reduce((acc, coupon) => acc + coupon.discountedAmount, 0)
    )
    lineItems.push({
      label: `Promo: ${couponCodes.join(' + ')}`,
      type: 'final',
      amount: `${Math.abs(toalCouponDiscount) * -1}`,
    })
  }
  if (order.isStoreCreditApplied && order.grandTotal > order.outstandingBalance) {
    lineItems.push({
      label: 'ZGold used',
      type: 'final',
      amount: String(order.grandTotal - order.outstandingBalance),
    })
  }
  if (order.totalDiscount) {
    lineItems.push({
      label: 'You saved',
      type: 'final',
      amount: String(order.totalDiscount),
    })
  }
  return lineItems
}

export default getApplePayOrderSummary
