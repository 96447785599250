import type { StripeModules } from '@/services/ApplePay/Stripe/fetchModules'
import { CSC_PAYMENTS_SHOP, CSC_SHOPFRONT_API } from '@/services/Configuration'

const isAcceptedOrderStatus = (status: string) => (
  [
    // 'Incomplete',
    // 'Pending',
    'Shipped',
    'Partially Shipped',
    // 'Refunded',
    // 'Cancelled',
    // 'Declined',
    // 'Awaiting Payment',
    'Awaiting Pickup',
    'Awaiting Shipment',
    'Completed',
    'Awaiting Fulfillment',
    'Manual Verification',
    // 'Disputed',
    // 'Partially Refunded',
  ].includes(status)
)

const Sleep = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms))
export const poolForOrderCompletionCheck = ({
  orderId,
  billingAddressZip,
  modulesPromise,
}: {
  orderId: string,
  billingAddressZip: string,
  modulesPromise: StripeModules,
}): Promise<{
  success: boolean,
}> => new Promise((resolve, reject) => {
  setTimeout(() => reject(new Error('poolForOrderCompletionCheck timed out')), 15000)
  const retry = () => {
    Sleep(150)
      .then(() => (
        poolForOrderCompletionCheck({
          orderId,
          billingAddressZip,
          modulesPromise,
        })
      ))
      .then(resolve)
      .catch(reject)
  }
  modulesPromise
    .then(async ({ ShopFront, credentialsPromise }) => (
      ShopFront({
        credentials: await credentialsPromise,
        host: CSC_SHOPFRONT_API,
      }).getOrder({
        orderId,
        shopName: CSC_PAYMENTS_SHOP,
        billingAddressZip,
      })
    ))
    .then((order) => {
      if (isAcceptedOrderStatus(String(order?.status))) {
        resolve({
          success: true,
        })
      } else {
        retry()
      }
    })
    .catch(reject)
})

export default poolForOrderCompletionCheck
