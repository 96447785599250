import type { ApplePay as ApplePayClient, Client as BraintreeClient } from 'braintree-web'

const getBraintreeApplePayClient = async (): Promise<ApplePayClient> => {
  // dynamically import necessary modules in parallel
  const [
    { getBraintreeClientToken },
    { getCart },
    { default: braintreeClient },
    { default: applePaySession },
  ] = await Promise.all([
    import('@/services/FramedCheckout/paymentServices/getBraintreeClientToken'),
    import('@/services/FramedCheckout/orderServices/getCart'),
    import('braintree-web/client'),
    import('braintree-web/apple-pay'),
  ])
  const cart = await getCart()
  if (!cart) {
    throw new Error('No cart')
  }
  // getBraintreeClientToken
  const authorization = await getBraintreeClientToken(cart?.id)
  const client = await (braintreeClient as BraintreeClient).create({
    authorization,
  })
  return (applePaySession as ApplePayClient).create({
    client,
  })
}

export default getBraintreeApplePayClient
