import { XsrfResilient } from '@/services/XsrfResilient'

import { error, log } from '@/services/Log'

import type { BCCheckoutType } from '@/types/BigCommerce/BCCheckout'
import allPromisesWithRetries from '@/helpers/allPromisesWithRetries'
import type { StandardCart } from '@/types/ShopFront/CheckoutStandards'

const ORDER_STATUS_INCOMPLETE = 'INCOMPLETE'

export const getOrder = XsrfResilient(async ({ cart, cart: { id: checkoutID } }: {
  cart: StandardCart
}) => {
  log('getOrder START', { cart, checkoutID })
  if (!checkoutID) {
    throw new Error('No checkout ID')
  }
  const [
    { default: Axios },
    { finalizePendingOrder },
    { standardizeOrder },
  ] = await allPromisesWithRetries(() => [
    import('axios'),
    import('./finalizePendingOrder'),
    import('@/services/Standardizers/checkout/standardizeOrder'),
  ])

  // Returns a Checkout. - https://developer.bigcommerce.com/api-reference/storefront/checkouts/checkout/checkoutsbycheckoutidget
  const { data: order } = await Axios.get<BCCheckoutType>(`/api/storefront/checkouts/${checkoutID}?include=consignments.availableShippingOptions`)
  const status = ORDER_STATUS_INCOMPLETE // default status, can be overridden by real status

  const newOrder = standardizeOrder({
    ...order,
    status,
    email: order.cart.email || order.billingAddress.email || '',
  }, cart)
  // Checking if the order has been finalized
  if (order?.orderId) {
    try {
      await finalizePendingOrder({
        standardOrder: {
          ...newOrder,
          orderId: String(order.orderId),
        },
      })
    } catch (err) {
      error('Failed to initialize pendig order', err)
    }
  }

  log('getOrder END', { newOrder })
  return newOrder
})

export default getOrder
