import type { StandardOrder } from '@/types/ShopFront/CheckoutStandards'

const allShipmentsAssigned = (order?: StandardOrder) => order?.shipments?.every((shipment) => (
  !!shipment.selectedShippingOption
))

export type UpdateWithDefinedOrder = {
  success: true,
  newOrder: StandardOrder
}
export type UpdateWithUndefinedOrder = {
  success: false,
  newOrder?: StandardOrder | undefined | null
}
export type FunctionUpdater<T, K = unknown> = (arg: T) => Promise<
K & (
  UpdateWithDefinedOrder | UpdateWithUndefinedOrder
)>

export const orderResponseIsNotReadyForPayment = (
  update: UpdateWithDefinedOrder | UpdateWithUndefinedOrder,
): update is UpdateWithUndefinedOrder => (
  !update.success
  || !update.newOrder
  || !allShipmentsAssigned(update.newOrder)
)

export const assertUpdateWithDefinedOrder = (
  update: UpdateWithDefinedOrder | UpdateWithUndefinedOrder,
  errormessage: string,
): UpdateWithDefinedOrder => {
  if (
    orderResponseIsNotReadyForPayment(update)
  ) {
    throw new Error(errormessage)
  }
  return update
}
