import { getApplePayOrderSummary } from './getApplePayOrderSummary'
import { getApplePayShippingMethods } from './getApplePayShippingMethods'

export const requiredContactFields: ApplePayJS.ApplePayContactField[] = ['postalAddress', 'phone', 'email', 'name']
export const merchantCapabilities: ApplePayJS.ApplePayMerchantCapability[] = ['supports3DS', 'supportsCredit', 'supportsDebit']

export const transformOrderToApplePaySessionData = (
  order: {
    isStoreCreditApplied?: boolean
    shipments: Array<{
      selectedShippingOption: null | {
        cost: number
      },
      availableShippingOptions: Array<{
        id: string
        title: string
        description: string
        cost: number
      }>
    }>
    subtotal: number
    taxTotal: number
    totalDiscount: number
    cart: {
      coupons: Array<{
        code: string
        discountedAmount: number
      }>
    }
    grandTotal: number,
    outstandingBalance: number,
  },
) => ({
  countryCode: 'US',
  currencyCode: 'USD',
  merchantCapabilities,
  supportedNetworks: [
    'visa',
    'masterCard',
    'amex',
    'discover',
  ],
  requiredBillingContactFields: requiredContactFields,
  requiredShippingContactFields: requiredContactFields,
  shippingMethods: getApplePayShippingMethods(order),
  lineItems: getApplePayOrderSummary(order),
  total: {
    label: 'Total',
    type: undefined,
    amount: String(order.outstandingBalance),
  },
})

export type ApplePaySessionData = ReturnType<typeof transformOrderToApplePaySessionData>

export default transformOrderToApplePaySessionData
