type LineItemsTransformer = (i: {
  lineItems: Array<{
    salePrice: number,
    quantity: number,
    discounts?: Array<{ discountedAmount: number }>
    originalName: string
    sku: string
    id: unknown
    productId: number
  }>
}) => Array<{
  id: string
  quantity: number
  sku: string
  name: string
  productId: string
  unitPrice: number
  unitTax: number
  itemDiscount: number
  cartDiscount: number
}>
export const lineItemsTransformer: LineItemsTransformer = ({
  lineItems,
}) => lineItems.map((lineItem) => {
  const cartDiscount = lineItem?.discounts ? (
    lineItem?.discounts?.reduce((totalDiscount, currDiscount) => (
      totalDiscount + currDiscount.discountedAmount
    ), 0)
  ) : 0
  return {
    id: String(lineItem.id),
    quantity: lineItem.quantity,
    sku: lineItem.sku,
    name: lineItem.originalName,
    productId: String(lineItem.productId) || '',
    unitPrice: lineItem.salePrice - (cartDiscount / lineItem.quantity),
    unitTax: 0, // figure this out later
    itemDiscount: cartDiscount ?? 0,
    cartDiscount,
  }
})

export default lineItemsTransformer
