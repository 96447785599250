import { error, log } from '@/services/Log'

export const handleSessionCancelled = ({
  beforeFinalize,
}: {
  beforeFinalize?: () => Promise<void>,
}) => (
  event: ApplePayJS.Event,
) => {
  log('Apple Pay Session Cancelled ', event)
  if (beforeFinalize) {
    beforeFinalize()
      .catch((err) => {
        error('Error executing beforeFinalize session', err)
      })
  }
}

export default handleSessionCancelled
