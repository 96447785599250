import allPromisesWithRetries from '@/helpers/allPromisesWithRetries'

export const fetchModules = () => (
  allPromisesWithRetries(() => [
    import('@/services/FramedCheckout/shipmentServices/updateShippingAddress'),
    import('@/services/FramedCheckout/shipmentServices/updateShippingOption'),
    import('@/helpers/stateNameAcronymMapping'),
    import('@/services/FramedCheckout/orderServices/getOrder'),
    import('@/services/FramedCheckout/orderServices/getCart'),
    import('@/services/FramedCheckout/paymentServices/updateBillingAddress'),
    import('@/services/Account/getCustomer'),
    import('@/services/FramedCheckout/orderServices/postOrderFlow'),
    import('@/services/ApplePay/Stripe/confirmPaymentIntent'),
    import('@/services/FramedCheckout/paymentServices/completePaymentWithCSC'),
    import('@/services/FramedCheckout/paymentServices/cscPaymentsServices/getSavedInstrumentsCredentials'),
    import('@csc/csc-sdk'),
    import('@/services/FramedCheckout/navigationServices'),
    import('@/services/FramedCheckout/addressServices/getNonShippedStates'),
  ])
    .then(([
      { updateShippingAddress },
      { updateShippingOption },
      { mapStateToFullName, mapStateToAcronym },
      { getOrder },
      { getCart },
      { updateBillingAddress },
      { getCustomer },
      { postOrderFlow },
      { confirmPaymentIntent },
      { completePaymentWithCSC },
      { getSavedInstrumentsCredentials },
      { default: CSC },
      { goToConfirmation },
      { getNonShippedStates },
    ]) => ({
      updateShippingAddress,
      updateShippingOption,
      mapStateToFullName,
      mapStateToAcronym,
      getOrder,
      getCart,
      updateBillingAddress,
      customerPromise: getCustomer(),
      postOrderFlow,
      confirmPaymentIntent,
      completePaymentWithCSC,
      credentialsPromise: getSavedInstrumentsCredentials(),
      goToConfirmation,
      getNonShippedStates,
      CSC,
      ...CSC,
    }))
)

export type StripeModules = ReturnType<typeof fetchModules>
